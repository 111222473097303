import React from 'react';
import SEO from '../components/seo';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import styled from 'styled-components';
const SectionColmuns = styled.section`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 40px;

  @media screen and (max-width: 700px) {
    display: flex;
    flex-direction: column-reverse;
    article {
      padding-bottom: 1.5rem;
    }
  }
  article img {
    max-height: 56vh;
  }
  @media screen and (max-width: 700px) {
    display: flex;
    flex-direction: column-reverse;
    article {
      padding-bottom: 1.5rem;
    }
    article img {
      max-height: initial;
    }
  }
`;
const ArticleDescription = styled.section`
  h1,
  h2 {
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
  }
  a {
    color: black;
    background:linear-gradient(180deg,#804444,#804444) no-repeat 0 1rem;
    background-size:100% 100%;
    transition:background-position-y .15s ease-out;
    text-decoration:none;
    color:black;
    font-weight:bold;
    transition:background 0.2s, color 0.2s;
  }
  a:active,
  a:focus,
  a:hover {
    background-position-y:0;
    color:white;
  }
`;

const Agence = () => (
  <StaticQuery
    query={graphql`
      query AgenceQuery {
        markdownRemark(frontmatter: { title: { eq: "Agence" } }) {
          frontmatter {
            title
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 600, quality: 72) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
              publicURL
            }
          }
          html
        }
      }
    `}
    render={data => (
      <>
        <SEO title="Agence" />
        <SectionColmuns>
          <article>
            <Img
              imgStyle={{ objectPosition: 'top center' }}
              fluid={
                data.markdownRemark.frontmatter.thumbnail.childImageSharp.fluid
              }
            />
          </article>
          <article>
            <ArticleDescription
              dangerouslySetInnerHTML={{
                __html: data.markdownRemark.html,
              }}
            />
          </article>
        </SectionColmuns>
      </>
    )}
  />
);

export default Agence;
